import { Person } from "../App"
import React, { useRef } from "react"
import { getConstant } from "../constants"
import {
  getPaymentURL,
  updateSignupAccount,
  sendToken,
  updateSignup,
  getBranchDetails,
  BranchContactDetails,
  sendJoinEvent
} from "../api"
import { useHistory, Link } from "react-router-dom"
import { RunIdleTimer } from "../components/RunIdleTimer"
import { Layout } from "../components/Layout"
import { Cell } from "baseui/layout-grid"
import { useStyletron } from "baseui"
import { ParagraphMedium } from "baseui/typography"
import { Notification } from "baseui/notification"
import { FormControl } from "../components/FormControl"
import {
  Controller,
  useForm,
  NestDataObject,
  FieldError
} from "react-hook-form"
import { Checkbox } from "../components/Checkbox"
import { Button } from "../components/Button"
import { ButtonRadios } from "../components/ButtonRadios"
import { MaskedInput } from "baseui/input"
import { Input } from "../components/Input"
import { OKModal } from "../components/OKModal"
import { findBranch, getDollarsAndCents } from "../util"
import { HeadingLevel, Heading } from "baseui/heading"
import { Coverage } from "./EmploymentDetailsForm"
interface FormValues {
  agree: boolean
  agree_dd: boolean
  paymentType: "cc" | "dd"
  bsb: string
  accountNo: string
  accountName: string
}
interface PaymentFormProps {
  person: Person
  setPerson: (person: Person | ((oldPerson: Person) => Person)) => void
  preselectedPaymentType?: FormValues["paymentType"]
  resetPersonalDetails: (completeReset?: boolean) => void
}
interface CloudPaymentsIframe {
  url: string
  iframeEl: React.RefObject<HTMLIFrameElement>
  counter: number
}

const IFRAME_HOST =
  getConstant("environment") === "test" ? "*" : getConstant("cloudPaymentsHost")
// Iframe that mimics the messages the real iframe will send
function FakeIframe({
  iframeEl
}: {
  iframeEl: React.RefObject<HTMLIFrameElement>
}) {
  return (
    <iframe
      ref={iframeEl}
      title="Fake iFrame"
      srcDoc={`
    <!DOCTYPE html>
    <html>
    <head>
    <script>
    var counter = 1;
    function receiveMessage(event) {
      if (event.data == "form.submit") {
        switch(counter) {
          case 1:
            window.parent.postMessage({message: "form.invalid", errors: "Test Error"}, '*')
            break;
          case 2:
            window.parent.postMessage({message: "transaction.cancelled"}, '*')
            break;
          case 3:
            window.parent.postMessage({message: "transaction.error"}, '*')
            break;
          case 4:
            window.parent.postMessage({message: "transaction.complete", data: {
                "r": "1",
                "postmessage": "true",
                "card_category": "Credit",
                "card_expiry": "12/2021",
                "card_holder": "Mr Banana",
                "card_number": "512345XXXXXX2346",
                "card_subcategory": "Standard",
                "card_type": "MasterCard",
                "css": "https://storage.googleapis.com/embeddablejoin.australianunions.org.au/css/paynow.css",
                "css_signature": "ba44cde90bdf0594073455fe088b9067",
                "iframe": "true",
                "show_email": "false",
                "show_extras": "false",
                "token": "0jhatwel3xm2du1wr25d",
                "tokenize_only": "true",
                "v": "8e6937e4751cab7994ecdff0d7a6c8fe",
                "sca_enabled": "",
                "merchant": "TESTactu",
                "amount": "5200",
                "currency": "AUD"
            }}, '*')
            break;
          }
      }
      counter++;
    }
    window.addEventListener("message", receiveMessage, false);
    </script>
    </head>
    <body>
    </body>
    </html>
  `}
    ></iframe>
  )
}
function CloudPaymentsIframe({ counter, iframeEl, url }: CloudPaymentsIframe) {
  const [css] = useStyletron()
  if (url) {
    return (
      <iframe
        key={counter}
        ref={iframeEl}
        className={css({
          width: "100%",
          maxWidth: "315px",
          height: "369px",
          border: "none"
        })}
        title="Union Membership Payment"
        src={url}
      ></iframe>
    )
  } else {
    return null
  }
}
interface DirectDebitFieldsProps {
  register: Function
  errors: NestDataObject<FormValues, FieldError>
}
function DirectDebitFields({ register, errors }: DirectDebitFieldsProps) {
  return (
    <>
      <Cell span={[4, 8, 12]}>
        <FormControl
          error={errors.accountName && errors.accountName.message}
          label="Account Name"
        >
          <Input
            inputRef={register({
              required: "Please enter your account name"
            })}
            overrides={{
              InputContainer: { style: { width: "315px" } },
              Input: { props: { maxLength: 32 } }
            }}
            error={!!errors.accountName}
            id="accountName"
            name="accountName"
          />
        </FormControl>
      </Cell>
      <Cell span={[4, 8, 12]}>
        <FormControl
          error={errors.accountNo && errors.accountNo.message}
          label="Account Number"
        >
          <Input
            inputRef={register({
              required: "Please enter your account number",
              pattern: {
                value: /^(\d{6}|\d{7}|\d{8}|\d{9})$/,
                message:
                  "Account number must be between 6 and 9 digits long and only contain digits (no spaces or dashes)"
              }
            })}
            overrides={{
              InputContainer: { style: { width: "315px" } },
              Input: { props: { maxLength: 9 } }
            }}
            error={!!errors.accountNo}
            id="accountNo"
            name="accountNo"
          />
        </FormControl>
      </Cell>
      <Cell span={[4, 8, 12]}>
        <FormControl
          error={errors.bsb && errors.bsb.message}
          label="BSB Number"
        >
          <MaskedInput
            mask="999-999"
            inputRef={register({
              required: "Please enter your BSB",
              pattern: {
                value: /^\d{3}-\d{3}$/,
                message: "BSB must be in the format 123-456"
              }
            })}
            overrides={{ InputContainer: { style: { width: "111px" } } }}
            error={!!errors.bsb}
            id="bsb"
            name="bsb"
          />
        </FormControl>
      </Cell>
    </>
  )
}

const TermsAndConditionsBody = () => {
  const [css] = useStyletron()
  const liClassname = css({
    marginBottom: "16px"
  })
  return (
    <>
      <ol>
        <li className={liClassname}>
          <ParagraphMedium>
            By submitting this Application, you understand and agree that you
            wish to become a member of the union that you have selected as a
            part of this application (the "Selected Union"), or, where this
            could not be determined during the application process, which the
            Australian Council of Trades Unions (the "ACTU") determines at a
            later time, where possible.
          </ParagraphMedium>
        </li>
        <li className={liClassname}>
          <ParagraphMedium>
            You agree to these terms and conditions between you and the Selected
            Union.
          </ParagraphMedium>
        </li>
        <li className={liClassname}>
          <ParagraphMedium>
            In the event your union is not known at the time you make this
            Application, the ACTU will promptly determine which of its
            affiliated unions has the right to represent you based on the
            coverage over the work you perform, where possible. At such time,
            you will be re-sent these Terms and Conditions and the Selected
            Union will receive your membership application. You must notify the
            Selected Union within 24 hours if you wish to cancel your
            Application at this stage. In the event that you cancel your
            Application, or the ACTU determines that you are not covered by any
            union, any fees paid to the Selected Union in connection with the
            Application shall be refunded.
          </ParagraphMedium>
        </li>
        <li className={liClassname}>
          <ParagraphMedium>
            You understand and agree to be bound by the rules of the Selected
            Union, which may be found on the Fair Work Commission’s website or
            obtained directly from the union.
          </ParagraphMedium>
        </li>
        <li className={liClassname}>
          <ParagraphMedium>
            You understand and agree to be bound by your Selected Union’s
            privacy policy, which may be found on its website.
          </ParagraphMedium>
        </li>
        <li className={liClassname}>
          <ParagraphMedium>
            You understand that your Selected Union reserves the right not to
            assist members with workplace issues that arose before they joined
            the union. If unions assisted everyone with a pre-existing issue,
            they would quickly run out of resources and be unable to assist
            existing members. Nevertheless, your Selected Union may be able to
            assist you with a pre-existing issue once certain conditions it has
            set at its sole discretion are met.
          </ParagraphMedium>
        </li>
        <li className={liClassname}>
          <ParagraphMedium>
            You understand that membership of your Selected Union may be subject
            to additional requirements. Until your membership application is
            accepted by your Selected Union, you may not be eligible for
            benefits of union membership. In the event your membership
            application is denied for any reason, the Selected Union will refund
            any fees paid, if at all, in connection with the Application and
            will have no further liability.
          </ParagraphMedium>
        </li>
        <li className={liClassname}>
          <ParagraphMedium>
            You authorise your union membership fees to be deducted on a monthly
            basis from the bank account or credit card indicated on your
            Application. The fee for your first month of membership is the
            amount stated and agreed by you in your Application and shall be
            paid to the Selected Union. After the first month, you agree and
            authorise your Selected Union to vary the amount of fees deducted,
            from time to time, in accordance with its own fee rates set in
            accordance with its rules. Information regarding variations to your
            fees may be found on your Selected Union’s website or will be
            provided to you in notices from your Selected Union. This
            authorisation shall remain in force until you advise your Selected
            Union otherwise.
          </ParagraphMedium>
        </li>
        <li className={liClassname}>
          <ParagraphMedium>
            You agree that upon the acceptance of your membership application to
            join your Selected Union, the ACTU shall have no further obligations
            in relation to your Application or these terms and conditions.
          </ParagraphMedium>
        </li>
        <li className={liClassname}>
          <ParagraphMedium>
            You understand that the ACTU is not a union and cannot individually
            represent you regarding industrial issues. The only service the ACTU
            is providing under the Application and these terms and conditions is
            assistance in the process of finding and joining your Selected
            Union.
          </ParagraphMedium>
        </li>
        <li className={liClassname}>
          <ParagraphMedium>
            You agree that all information provided in the Application is true
            and correct.
          </ParagraphMedium>
        </li>
      </ol>
    </>
  )
}

const UnionAddressDetails = ({
  branchContactDetails
}: {
  branchContactDetails?: BranchContactDetails
}) => {
  if (branchContactDetails) {
    const {
      address_street1,
      address_street2,
      address_suburb,
      address_state,
      address_postcode
    } = branchContactDetails
    return (
      <>
        {address_street1 ? (
          <>
            {address_street1}
            <br />
          </>
        ) : (
          ""
        )}
        {address_street2 ? (
          <>
            {address_street2}
            <br />
          </>
        ) : (
          ""
        )}
        {address_suburb && address_state ? (
          <>
            {address_suburb}, {address_state}
            <br />
          </>
        ) : (
          ""
        )}
        {address_postcode ? address_postcode : null}
      </>
    )
  }
  return null
}

const DisputeParagraph = ({
  branchContactDetails
}: {
  branchContactDetails?: BranchContactDetails | null
}) => {
  let contactDetails = ""
  if (branchContactDetails) {
    if (branchContactDetails.phone1) {
      contactDetails = ` on ${branchContactDetails.phone1}`
      if (branchContactDetails.branch_email) {
        contactDetails += ` and at ${branchContactDetails.branch_email}`
      }
    } else if (branchContactDetails.branch_email) {
      contactDetails = ` at ${branchContactDetails.branch_email}`
    }
  }
  return (
    <ParagraphMedium>
      If you believe that there has been an error in debiting your account, you
      should notify us directly{contactDetails} as soon as possible so that we
      can resolve your query more quickly. Alternatively, you can take it up
      directly with your financial institution.
    </ParagraphMedium>
  )
}

const DirectDebitTermsBody = ({
  branchContactDetails,
  branch
}: {
  branchContactDetails?: BranchContactDetails
  branch?: Coverage | null
}) => {
  return (
    <>
      <ParagraphMedium>{branch ? branch.full_name : ""}</ParagraphMedium>
      <ParagraphMedium>
        <UnionAddressDetails branchContactDetails={branchContactDetails} />
      </ParagraphMedium>

      <ParagraphMedium>
        This is your Direct Debit Service Agreement with{" "}
        {branch ? branch.full_name : "the union"}
        {branch && branch.apca_id
          ? `, APCA User ID Number ${branch.apca_id}`
          : ""}
        . It explains what your obligations are when undertaking a Direct Debit
        arrangement with us. It also details what our obligations are to you as
        your Direct Debit provider.
      </ParagraphMedium>
      <ParagraphMedium>
        Please keep this agreement for future reference. It forms part of the
        terms and conditions of your Direct Debit Request (DDR) and should be
        read in conjunction with your DDR authorisation.
      </ParagraphMedium>
      <HeadingLevel>
        <Heading styleLevel={6}>Definitions</Heading>
      </HeadingLevel>

      <ul>
        <li>
          <ParagraphMedium>
            <strong>account</strong> means the account held at your financial
            institution from which we are authorised to arrange for funds to be
            debited.
          </ParagraphMedium>
        </li>
        <li>
          <ParagraphMedium>
            <strong>agreement</strong> means this Direct Debit Request Service
            Agreement between you and us.
          </ParagraphMedium>
        </li>
        <li>
          <ParagraphMedium>
            <strong>banking day</strong> means a day other than a Saturday or a
            Sunday or a public holiday listed throughout Australia.
          </ParagraphMedium>
        </li>
        <li>
          <ParagraphMedium>
            <strong>debit day</strong> means the day that payment by you to us
            is due.
          </ParagraphMedium>
        </li>
        <li>
          <ParagraphMedium>
            <strong>debit payment</strong> means a particular transaction where
            a debit is made.
          </ParagraphMedium>
        </li>
        <li>
          <ParagraphMedium>
            <strong>direct debit request</strong> means the Direct Debit Request
            between us and you.
          </ParagraphMedium>
        </li>
        <li>
          <ParagraphMedium>
            <strong>us or we</strong> means the{" "}
            {branch ? branch.full_name : "union"} (the Debit User) you have
            authorised by requesting a Direct Debit Request.
          </ParagraphMedium>
        </li>
        <li>
          <ParagraphMedium>
            <strong>you</strong> means the customer who has signed or authorised
            by other means the Direct Debit Request.
          </ParagraphMedium>
        </li>
        <li>
          <ParagraphMedium>
            <strong>your financial institution</strong> means the financial
            institution nominated by you on the DDR at which the account is
            maintained.
          </ParagraphMedium>
        </li>
      </ul>

      <HeadingLevel>
        <Heading styleLevel={6}>Debiting your account</Heading>
      </HeadingLevel>

      <ol>
        <li>
          <ParagraphMedium>
            By signing a Direct Debit Request or by providing us with a valid
            instruction, you have authorised us to arrange for funds to be
            debited from your account. You should refer to the Direct Debit
            Request and this agreement for the terms of the arrangement between
            us and you.
          </ParagraphMedium>
        </li>
        <li>
          <ParagraphMedium>
            We will only arrange for funds to be debited from your account as
            authorised in the Direct Debit Request.
          </ParagraphMedium>
        </li>
        <li>
          <ParagraphMedium>
            If the debit day falls on a day that is not a banking day, we may
            direct your financial institution to debit your account on the
            following banking day. If you are unsure about which day your
            account has or will be debited you should ask your financial
            institution.
          </ParagraphMedium>
        </li>
      </ol>

      <HeadingLevel>
        <Heading styleLevel={6}>Amendments by us</Heading>
      </HeadingLevel>
      <ParagraphMedium>
        We may vary any details of this agreement or a Direct Debit Request at
        any time by giving you at least fourteen (14) days written notice.
      </ParagraphMedium>

      <HeadingLevel>
        <Heading styleLevel={6}>Amendments by you</Heading>
      </HeadingLevel>
      <ParagraphMedium>
        You may change, stop or defer a debit payment, or terminate this
        agreement by providing us with at least 14 days notification by emailing{" "}
        {branchContactDetails && branchContactDetails.branch_email
          ? branchContactDetails.branch_email
          : "us"}{" "}
        or arranging it through your own financial institution, which is
        required to act promptly on your instructions.
      </ParagraphMedium>

      <HeadingLevel>
        <Heading styleLevel={6}>Your obligations</Heading>
      </HeadingLevel>
      <ol>
        <li>
          <ParagraphMedium>
            It is your responsibility to ensure that there are sufficient clear
            funds available in your account to allow a debit payment to be made
            in accordance with the Direct Debit Request.
          </ParagraphMedium>
        </li>
        <li>
          <ParagraphMedium>
            If there are insufficient clear funds in your account to meet a
            debit payment:
          </ParagraphMedium>
          <ol type="a">
            <li>
              <ParagraphMedium>
                you may be charged a fee and/or interest by your financial
                institution;
              </ParagraphMedium>
            </li>
            <li>
              <ParagraphMedium>
                you may also incur fees or charges imposed or incurred by us;
                and
              </ParagraphMedium>
            </li>
            <li>
              <ParagraphMedium>
                you must arrange for the debit payment to be made by another
                method or arrange for sufficient clear funds to be in your
                account by an agreed time so that we can process the debit
                payment.
              </ParagraphMedium>
            </li>
          </ol>
        </li>
        <li>
          <ParagraphMedium>
            You should check your account statement to verify that the amounts
            debited from your account are correct.
          </ParagraphMedium>
        </li>
      </ol>

      <HeadingLevel>
        <Heading styleLevel={6}>Dispute</Heading>
      </HeadingLevel>

      <ol>
        <li>
          <DisputeParagraph branchContactDetails={branchContactDetails} />
        </li>
        <li>
          <ParagraphMedium>
            If we conclude as a result of our investigations that your account
            has been incorrectly debited we will respond to your query by
            arranging for your financial institution to adjust your account
            (including interest and charges) accordingly. We will also notify
            you in writing of the amount by which your account has been
            adjusted.
          </ParagraphMedium>
        </li>
        <li>
          <ParagraphMedium>
            If we conclude as a result of our investigations that your account
            has not been incorrectly debited we will respond to your query by
            providing you with reasons and any evidence for this finding in
            writing.
          </ParagraphMedium>
        </li>
      </ol>

      <HeadingLevel>
        <Heading styleLevel={6}>Accounts</Heading>
      </HeadingLevel>

      <ParagraphMedium>You should check:</ParagraphMedium>
      <ol>
        <li>
          <ParagraphMedium>
            with your financial institution whether direct debiting is available
            from your account as direct debiting is not available on all
            accounts offered by financial institutions;
          </ParagraphMedium>
        </li>
        <li>
          <ParagraphMedium>
            your account details which you have provided to us are correct by
            checking them against a recent account statement; and
          </ParagraphMedium>
        </li>
        <li>
          <ParagraphMedium>
            with your financial institution before completing the Direct Debit
            Request if you have any queries about how to complete the Direct
            Debit Request.
          </ParagraphMedium>
        </li>
      </ol>

      <HeadingLevel>
        <Heading styleLevel={6}>Confidentiality</Heading>
      </HeadingLevel>

      <ol>
        <li>
          <ParagraphMedium>
            We will keep any information (including your account details) in
            your Direct Debit Request confidential. We will make reasonable
            efforts to keep any such information that we have about you secure
            and to ensure that any of our employees or agents who have access to
            information about you do not make any unauthorised use,
            modification, reproduction or disclosure of that information.
          </ParagraphMedium>
        </li>
        <li>
          <ParagraphMedium>
            We will only disclose information that we have about you:
          </ParagraphMedium>
          <ol type="a">
            <li>
              <ParagraphMedium>
                to the extent specifically required by law; or
              </ParagraphMedium>
            </li>
            <li>
              <ParagraphMedium>
                for the purposes of this agreement (including disclosing
                information in connection with any query or claim).
              </ParagraphMedium>
            </li>
          </ol>
        </li>
      </ol>

      <HeadingLevel>
        <Heading styleLevel={6}>Notice</Heading>
      </HeadingLevel>

      <ol>
        <li>
          <ParagraphMedium>
            If you wish to notify us in writing about anything relating to this
            agreement, please email{" "}
            {branchContactDetails && branchContactDetails.branch_email
              ? branchContactDetails.branch_email
              : "us"}
            .
          </ParagraphMedium>
        </li>
        <li>
          <ParagraphMedium>
            We will notify you by sending a notice by the email you have given
            us in the Direct Debit Request.
          </ParagraphMedium>
        </li>
        <li>
          <ParagraphMedium>
            Any notice will be deemed to have been received by you as soon as it
            is capable of being retrieved.
          </ParagraphMedium>
        </li>
      </ol>

      <HeadingLevel>
        <Heading styleLevel={6}>Direct Debit Payment Authorisation</Heading>
      </HeadingLevel>
      <ParagraphMedium>
        I request and authorise {branch ? branch.full_name : "the union"}
        {branch && branch.apca_id
          ? `, APCA User ID Number ${branch.apca_id}`
          : ""}{" "}
        to arrange, through its own financial institution, a debit to my
        nominated account any amount the {branch ? branch.full_name : "union"}{" "}
        has deemed payable.
      </ParagraphMedium>
    </>
  )
}

export const Modal = ({
  modalType,
  isOpen,
  close,
  branch,
  branchContactDetails
}: {
  modalType: "dd" | "terms"
  isOpen: boolean
  close: () => void
  branch?: Coverage | null
  branchContactDetails?: BranchContactDetails
}) => {
  const title =
    modalType === "terms" ? (
      <>
        <div>
          Australian Unions Joining Process Terms & Conditions (Version 1.1 2
          August 2020)
        </div>
      </>
    ) : (
      `Direct Debit Service Agreement`
    )
  return (
    <OKModal
      close={close}
      isOpen={isOpen}
      body={
        modalType === "terms" ? (
          <TermsAndConditionsBody />
        ) : (
          <DirectDebitTermsBody
            branchContactDetails={branchContactDetails}
            branch={branch}
          />
        )
      }
      title={title}
      buttonText="Okay"
    />
  )
}
const NoCreditCardMessage = (props: { name: string; onClick: () => void }) => {
  const [css] = useStyletron()
  return (
    <ParagraphMedium
      overrides={{
        Block: { style: { marginTop: "20px", marginBottom: "36px" } }
      }}
    >
      {props.name} does not collect credit card details through this form. We
      will send them your details and they will contact you directly to set up
      your credit card billing. Alternatively you can{" "}
      <button
        onClick={e => {
          props.onClick()
        }}
        className={css({
          background: "none",
          border: "none",
          padding: 0,
          textDecoration: "underline",
          cursor: "pointer",
          fontSize: "16px",
          fontFamily: "Poppins"
        })}
      >
        pay by direct debit
      </button>
      .
    </ParagraphMedium>
  )
}

export const PaymentForm = ({
  person,
  setPerson,
  resetPersonalDetails,
  preselectedPaymentType
}: PaymentFormProps) => {
  const branch = findBranch(person)
  const branch_id = branch ? branch.branch_id : ""
  const unionName = (branch && branch.full_name) || "Australian Unions"
  const iframeEl = useRef<HTMLIFrameElement>(null)
  const [url, setURL] = React.useState("")
  const [counter, forceUpdate] = React.useReducer(x => x + 1, 0)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isTermsModalOpen, setIsTermsModalOpen] = React.useState(false)
  const [isDDModalOpen, setIsDDModalOpen] = React.useState(false)
  const [branchContactDetails, setBranchContactDetails] = React.useState<
    BranchContactDetails
  >({})
  const [formError, setFormError] = React.useState("")
  const [scrollTrigger, setScrollTrigger] = React.useState(1)
  // Need to force a rerender after a failed transaction because the iframe
  // url changes. Not very reacty, but not much of a choice.
  // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  React.useEffect(() => {
    if (branch_id) {
      getBranchDetails(branch_id)
        .then(branchContactDetails => {
          setBranchContactDetails(branchContactDetails)
        })
        .catch()
    }
  }, [branch_id])
  const { push } = useHistory()
  const {
    handleSubmit,
    control,
    setError,
    errors,
    watch,
    register,
    setValue
  } = useForm<FormValues>({
    defaultValues: {
      agree: false,
      paymentType: preselectedPaymentType
    }
  })
  const topError =
    formError ||
    (Object.keys(errors).length > 0
      ? "Please fill in the required information below"
      : "")
  const paymentType = watch("paymentType")
  const { dollars, cents } = getDollarsAndCents(person.cost)
  const goToNextPage = React.useCallback(() => {
    if (person.volunteer) {
      push("/volunteer-details")
    } else {
      push("/complete")
      resetPersonalDetails()
    }
  }, [person.volunteer, push, resetPersonalDetails])
  const trackPurchase = React.useCallback(() => {
    if (person.person_id && person.people_id) {
      sendJoinEvent(
        person.person_id,
        person.people_id,
        getConstant("joinPaymentDetailsEvent")
      )
    }
  }, [person])

  let ccElement: JSX.Element | null
  if (branch && branch.payment_mode === "stored") {
    ccElement = (
      <NoCreditCardMessage
        name={unionName}
        onClick={() => setValue("paymentType", "dd")}
      />
    )
  } else if (getConstant("environment") === "test") {
    ccElement = <FakeIframe iframeEl={iframeEl} />
  } else if (url) {
    ccElement = (
      <CloudPaymentsIframe iframeEl={iframeEl} counter={counter} url={url} />
    )
  } else {
    ccElement = null
  }

  const onSubmit = (values: FormValues) => {
    setFormError("")
    if (!values.agree) {
      setError(
        "agree",
        "value",
        "You must agree to the terms and conditions to complete your membership"
      )
    } else if (paymentType === "dd" && !values.agree_dd) {
      setError(
        "agree_dd",
        "value",
        "You must agree to the Direct Debit Service Agreement to complete your membership"
      )
    } else if (values.paymentType === "cc") {
      setPerson(p => {
        return { ...p, payment_type: values.paymentType }
      })
      if (branch && branch.payment_mode === "stored") {
        setIsLoading(true)
        updateSignup(person, branch_id, true)
          .then(() => {
            trackPurchase()
            goToNextPage()
          })
          .catch(() => {
            setFormError("Something went wrong, please try again")
            setScrollTrigger(value => value + 1)
            setIsLoading(false)
          })
      } else {
        setIsLoading(true)
        if (iframeEl && iframeEl.current && iframeEl.current.contentWindow) {
          iframeEl.current.contentWindow.postMessage("form.submit", IFRAME_HOST)
        }
      }
    } else if (values.paymentType === "dd") {
      setIsLoading(true)
      const updatedPerson = {
        ...person,
        payment_type: values.paymentType,
        bsb: values.bsb,
        accountName: values.accountName,
        accountNo: values.accountNo
      }
      setPerson(updatedPerson)
      updateSignupAccount(updatedPerson, branch_id)
        .then(() => {
          trackPurchase()
          goToNextPage()
        })
        .catch(() => {
          setFormError(
            "Something went wrong, please check your details and try again"
          )
          setScrollTrigger(value => value + 1)
          setIsLoading(false)
        })
    }
  }
  React.useEffect(() => {}, [paymentType, cents, dollars])
  const tokeniseOnly = !branch && paymentType === "cc"
  React.useEffect(() => {
    if (!person.people_id || !person.person_id) {
      push("/")
    }
    if (!person.cost) {
      // We do not have cost, navigate to previous screen
      push("/employment-details")
    }
    if (branch && branch.payment_mode === "fatzebra" && paymentType === "cc") {
      if (person && person.person_id && person.people_id) {
        getPaymentURL(person.person_id, person.people_id, branch.branch_id)
          .then(response => setURL(response.url))
          .catch(() => push("/employment-details"))
      } else {
        push("/employment-details")
      }
    } else if (tokeniseOnly && person.person_id && person.people_id) {
      getPaymentURL(person.person_id, person.people_id)
        .then(response => setURL(response.url))
        .catch(() => push("/employment-details"))
    }
  }, [person, push, paymentType, branch, tokeniseOnly])
  React.useEffect(() => {
    const messageListener = (event: MessageEvent) => {
      if (
        getConstant("environment") !== "test" &&
        event.origin !== IFRAME_HOST
      ) {
        return
      }
      // Older browsers will have a query-string style data payload
      // Whereas newer browsers will have an object
      let payload = event.data
      let message = payload.message
      if (payload.hasOwnProperty("data")) {
        payload = payload.data
      }

      switch (message) {
        case "form.valid":
          setFormError("")
          setIsLoading(false)
          break
        case "form.invalid":
          setFormError(payload.errors)
          if (isLoading) {
            setScrollTrigger(value => value + 1)
          }
          setIsLoading(false)
          break
        case "transaction.complete":
          trackPurchase()
          sendToken(
            payload,
            person.person_id as string,
            person.people_id as string
          )
            .then(() => {
              setIsLoading(false)
              goToNextPage()
            })
            .catch(() => {
              if (tokeniseOnly) {
                setIsLoading(false)
                setFormError(
                  "There was an issue saving your credit card. Please try again."
                )
                setScrollTrigger(value => value + 1)
                forceUpdate()
              } else {
                goToNextPage()
              }
            })
          break
        case "transaction.processing":
          // Handle the processing of the transaction - implementation optional.
          break
        case "transaction.cancelled":
        case "transaction.error":
          setIsLoading(false)
          setFormError(
            "There was an issue processing your credit card. Please check the details, make sure you have sufficient funds and try again."
          )
          setScrollTrigger(value => value + 1)
          forceUpdate()
          // Handle the transaction being cancelled (i.e. show message, re-display the window etc).
          break
      }
    }
    window.addEventListener("message", messageListener)

    return () => {
      window.removeEventListener("message", messageListener)
    }
  }, [
    push,
    person.volunteer,
    tokeniseOnly,
    isLoading,
    person.person_id,
    person.people_id,
    trackPurchase,
    goToNextPage,
    resetPersonalDetails
  ])
  const [css] = useStyletron()
  return (
    <form id="payment" onSubmit={handleSubmit(onSubmit)}>
      <Modal
        modalType={"terms"}
        isOpen={isTermsModalOpen}
        close={() => setIsTermsModalOpen(false)}
        branch={branch}
      />
      {
        <Modal
          modalType={"dd"}
          isOpen={isDDModalOpen}
          close={() => setIsDDModalOpen(false)}
          branch={branch}
          branchContactDetails={branchContactDetails}
        />
      }
      <RunIdleTimer resetPersonalDetails={resetPersonalDetails} />
      <Layout
        heading="Payment options"
        sectionStep={3}
        person={person}
        scrollTrigger={scrollTrigger}
        setPerson={setPerson}
      >
        <Cell span={[4, 8, 12]}>
          <ParagraphMedium>
            Your first month's membership fee will be ${dollars}.{cents}
            {branch?.payment_mode === "stored" && "*"}.
          </ParagraphMedium>
          <ParagraphMedium>
            {!branch
              ? `You'll be billed for your first month of membership once your union is confirmed. Your union will then let you know what fees apply after that. In most cases this will be very similar.`
              : branch.payment_mode === "stored"
              ? `Your union will bill this in the next few days, and will then let you know what fees apply after that. In most cases this will be very similar.`
              : `You will be billed for your first month of membership today. Your union will then let you know what fees apply after that. In most cases this will be very similar.`}
          </ParagraphMedium>
          {branch?.payment_mode === "stored" && (
            <ParagraphMedium>
              <em>
                *In some cases, branches may apply the applicable ongoing rate
                for the first billing period.
              </em>
            </ParagraphMedium>
          )}
        </Cell>
        {topError && (
          <Cell span={[4, 8, 12]}>
            <Notification
              overrides={{
                Body: { style: { width: "auto", marginBottom: "8px" } }
              }}
              kind="negative"
            >
              {topError}
            </Notification>
          </Cell>
        )}
        <Cell span={[4, 8, 12]}>
          <FormControl
            error={errors.paymentType && errors.paymentType.message}
            label="Payment Method"
          >
            <Controller
              as={ButtonRadios}
              name="paymentType"
              control={control}
              valueName={"currentValue"}
              onChangeName={"updateFunction"}
              rules={{
                required: "Please pick a payment method"
              }}
              mappings={[
                {
                  value: "cc",
                  display: "Credit Card"
                },
                {
                  value: "dd",
                  display: "Direct Debit"
                }
              ]}
            />
          </FormControl>
        </Cell>
        {paymentType === "cc" && <Cell span={[4, 8, 12]}>{ccElement}</Cell>}
        {paymentType === "dd" && (
          <DirectDebitFields register={register} errors={errors} />
        )}
        {paymentType && (
          <Cell span={[4, 8, 12]}>
            <div
              className={css({
                backgroundColor: "#eaeaea",
                borderRadius: "5px",
                padding: "20px"
              })}
            >
              <FormControl
                error={errors.agree && errors.agree.message}
                label=""
              >
                <Controller
                  control={control}
                  name="agree"
                  error={!!errors.agree}
                  as={Checkbox}
                  overrides={{
                    Root: {
                      style: {
                        marginTop: 0
                      }
                    }
                  }}
                  onChange={([e]) => e.currentTarget.checked}
                  valueName="checked"
                >
                  I have read and agree to the{" "}
                  <Link to="/payment" onClick={() => setIsTermsModalOpen(true)}>
                    terms and conditions
                  </Link>
                  . I agree to become a member of the selected union and be
                  bound by its rules.
                </Controller>
              </FormControl>
              {paymentType === "dd" && (
                <FormControl
                  error={errors.agree_dd && errors.agree_dd.message}
                  label=""
                >
                  <Controller
                    control={control}
                    name="agree_dd"
                    error={!!errors.agree_dd}
                    as={Checkbox}
                    onChange={([e]) => e.currentTarget.checked}
                    valueName="checked"
                  >
                    I have read and agree to the{" "}
                    <Link to="/payment" onClick={() => setIsDDModalOpen(true)}>
                      Direct Debit Service Agreement.
                    </Link>
                  </Controller>
                </FormControl>
              )}
              <Button
                overrides={{
                  BaseButton: {
                    style: {
                      marginBottom: "0",
                      marginTop: "16px"
                    }
                  }
                }}
                isLoading={isLoading}
              >
                {!(
                  paymentType === "cc" &&
                  branch &&
                  branch.payment_mode === "stored"
                )
                  ? "Pay and Finish"
                  : "Finish"}
              </Button>
            </div>
          </Cell>
        )}
      </Layout>
    </form>
  )
}
